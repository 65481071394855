exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-achievements-js": () => import("./../../../src/pages/achievements.js" /* webpackChunkName: "component---src-pages-achievements-js" */),
  "component---src-pages-articles-js": () => import("./../../../src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-certifications-full-js": () => import("./../../../src/pages/certifications.full.js" /* webpackChunkName: "component---src-pages-certifications-full-js" */),
  "component---src-pages-certifications-js": () => import("./../../../src/pages/certifications.js" /* webpackChunkName: "component---src-pages-certifications-js" */),
  "component---src-pages-education-js": () => import("./../../../src/pages/education.js" /* webpackChunkName: "component---src-pages-education-js" */),
  "component---src-pages-experience-js": () => import("./../../../src/pages/experience.js" /* webpackChunkName: "component---src-pages-experience-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-officehours-js": () => import("./../../../src/pages/officehours.js" /* webpackChunkName: "component---src-pages-officehours-js" */),
  "component---src-pages-people-js": () => import("./../../../src/pages/people.js" /* webpackChunkName: "component---src-pages-people-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-publications-js": () => import("./../../../src/pages/publications.js" /* webpackChunkName: "component---src-pages-publications-js" */),
  "component---src-pages-research-js": () => import("./../../../src/pages/research.js" /* webpackChunkName: "component---src-pages-research-js" */),
  "component---src-pages-talks-js": () => import("./../../../src/pages/talks.js" /* webpackChunkName: "component---src-pages-talks-js" */),
  "component---src-pages-volunteering-js": () => import("./../../../src/pages/volunteering.js" /* webpackChunkName: "component---src-pages-volunteering-js" */),
  "component---src-pages-workshops-js": () => import("./../../../src/pages/workshops.js" /* webpackChunkName: "component---src-pages-workshops-js" */)
}

